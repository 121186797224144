import React, { useEffect } from 'react'

const usePreventBodyScroll = (menuIsOpened) => {
	useEffect(() => {
		return menuIsOpened
			? document.body.classList.add('no-scroll')
			: document.body.classList.remove('no-scroll')
	}, [menuIsOpened])
	return null
}

export default usePreventBodyScroll
