import { createContext } from 'react'

export const SignUpContext = createContext({
	email: '',
	setEmail: () => {},
})

export const LoginContext = createContext({
	logedIn: '',
	setLogedIn: () => {},
})
