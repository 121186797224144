import React from 'react'
const StripeSuccess = React.lazy(() => import('../components/modules/StripeSuccess'))

const Landing = React.lazy(() => import('../components/templates/Landing'))
const LogIn = React.lazy(() => import('../components/modules/LogIn'))
const SignUp = React.lazy(() => import('../components/modules/SignUp'))
const EmailConfirmation = React.lazy(() =>
	import('../components/modules/EmailConfirmation')
)
const Dashboard = React.lazy(() => import('../components/templates/Dashboard'))
const Deals = React.lazy(() => import('../components/modules/Dashboard/Deals'))
const Overview = React.lazy(() =>
	import('../components/modules/Dashboard/Overview')
)
const Privacy = React.lazy(() => import('../components/templates/Privacy'))
const Terms = React.lazy(() => import('../components/templates/Terms'))
const Notes = React.lazy(() => import('../components/templates/Notes'))
const UserGuide = React.lazy(() => import('../components/templates/UserGuide'))
const QuickCalc = React.lazy(() =>
	import('../components/modules/Dashboard/Questionnaire/QuickCalc')
)
const DealWizard = React.lazy(() =>
	import('../components/modules/Dashboard/Questionnaire/DealWizard')
)
const PropertyInfo = React.lazy(() =>
	import(
		'../components/modules/Dashboard/Questionnaire/PropertyInfo/PropertyInfo'
	)
)
const Financing = React.lazy(() =>
	import('../components/modules/Dashboard/Questionnaire/Financing/Financing')
)
const IncomeExpenses = React.lazy(() =>
	import(
		'../components/modules/Dashboard/Questionnaire/IncomeExpenses/IncomeExpenses'
	)
)

const routes = [
	{
		name: 'homepage',
		path: '/',
		exact: true,
		element: <Landing />,
	},
	{
		name: 'stripe-success',
		path: '/stripe-success',
		exact: true,
		element: <StripeSuccess />,
	},
	{
		name: 'login',
		path: '/login',
		exact: true,
		element: <LogIn />,
	},
	{
		name: 'signup',
		path: '/signup',
		exact: true,
		element: <SignUp />,
	},
	{
		name: 'emailConfirmation',
		path: '/account/:activation',
		exact: true,
		element: <EmailConfirmation />,
	},
	{
		name: 'Dashboard',
		path: '/dashboard',
		exact: true,
		element: <Dashboard />,
		isPrivate: true,
	},
	{
		name: 'Deals',
		path: '/deals',
		exact: true,
		element: <Deals />,
		isPrivate: true,
	},
	{
		name: 'DealWizard',
		path: '/deals/:dealId',
		exact: true,
		element: <DealWizard />,
		isPrivate: true,
	},
	{
		name: 'PropertyInfo',
		path: '/deals/property-info/:dealId',
		exact: true,
		element: <PropertyInfo />,
		isPrivate: true,
	},
	{
		name: 'Financing',
		path: '/deals/financing/:dealId',
		exact: true,
		element: <Financing />,
		isPrivate: true,
	},
	{
		name: 'IncomeExpenses',
		path: '/deals/income-expenses/:dealId',
		exact: true,
		element: <IncomeExpenses />,
		isPrivate: true,
	},
	{
		name: 'QuickCalc',
		path: '/deals/:dealId/quick-calc',
		exact: true,
		element: <QuickCalc />,
	},
	{
		name: 'Overview',
		path: 'deals/:dealId/overview',
		exact: true,
		element: <Overview />,
		isPrivate: true,
	},
	{
		name: 'Privacy',
		path: '/privacy',
		exact: true,
		element: <Privacy />,
	},
	{
		name: 'Terms',
		path: '/terms',
		exact: true,
		element: <Terms />,
	},
	{
		name: 'Notes',
		path: '/release-notes',
		exact: true,
		element: <Notes />,
	},
	{
		name: 'UserGuide',
		path: '/user-guide',
		exact: true,
		element: <UserGuide />,
	},
]

const routesMap = {}

routes.forEach((route) => {
	if (Object.prototype.hasOwnProperty.call(routesMap, 'name')) {
		routesMap[route.name] = route.path
		console.log({ route })
	}
})

function urlBuilder(name, params) {
	if (!Object.prototype.hasOwnProperty.call(routesMap, name)) {
		return null
	}

	let url = routesMap[name] // news/:id

	for (const key in params) {
		url = url.replace(`:${key}`, params[key])
	}

	return url
}

export default routes
export { routesMap, urlBuilder }
